.el-form {
  .title {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 24px;
    font-family: 'GTWalsheimPro-Bold';
    color: $primary-purple;
  }

  p {
    font-size: 16px;
    font-weight: 200;
    line-height: 1.56;
    color: $primary-purple;
    margin-bottom: 40px;
  }

  .labelTitle {
    label {
      font-size: 18px;
    }
  }

  /deep/ .el-form-item__error {
    padding-bottom: 10px;
  }
}

@media (max-width: 550px) {
  .el-form {
    p {
      margin-bottom: 31px;
    }

    ul {
      .label {
        font-size: 16px;
      }

      /deep/ .el-radio-button .el-radio-button__inner {
        font-size: 14px;
        white-space: inherit;
      }
    }
  }
}
